import { ExamplePageDefault } from './Default'
import './index.scss'
import { Weather } from 'generated/backendSchemas'

export type Props = {
  type: 'default' | 'eva' | 'patternC' | 'patternD'
  data: Weather
}

export const Presenter = ({ type, data }: Props) => {

  switch (type) {
    case 'default':
      return <ExamplePageDefault {...data} />
    default: {
      throw new Error( type )
    }
  }
}

import { GetSlideshowResponse } from 'generated/backendComponents'
import { MoviePage } from 'src/components/pages/movie'
import { PicturePage } from 'src/components/pages/picture'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay } from 'swiper'
import { HeatstrokePage } from '../../components/pages/heatstroke'
import { NoisePage } from '../../components/pages/noise'
import { SchedulePage } from '../../components/pages/schedule'
import { WeatherPage } from '../../components/pages/weather_forecast'
import { WeatherGaugePage } from 'src/components/pages/weather_gauge'
import { ZeroAccidentPage } from '../../components/pages/zeroaccident'
import { AiContentPage } from '../../components/pages/aicontent'
import { useDownloadVideoFromGetSlideshowResponse, useSlideshow } from './hooks'
import { Loading } from 'src/components/atoms/Loading'

import { useSizeStore } from 'src/zustand/useSizeStore'
import { useWindowSize } from 'src/hooks'

export type ResponseSlide = NonNullable<GetSlideshowResponse['slides']>[number]

SwiperCore.use([Autoplay])

type SlideProps = {
  responseSlide: ResponseSlide
  slideIndex: number
  isActive: boolean
}

const Slide = ({ slideIndex, responseSlide: { id, type }, isActive }: SlideProps) => {

  const { setSize } = useSizeStore()

  // @ts-ignore
  useWindowSize()
  
  switch (type) {
    case 'schedule':
      return <SchedulePage contentId={id ?? 0} slideIndex={slideIndex} isActive={ isActive } />
    case 'weather':
      return <WeatherPage contentId={id ?? 0} slideIndex={slideIndex} isActive={ isActive } />
    case 'vnmeter':
      return <NoisePage contentId={id ?? 0} slideIndex={slideIndex} isActive={ isActive } />
    case 'wbgt':
      return <HeatstrokePage contentId={id ?? 0} slideIndex={slideIndex} isActive={ isActive } />
    case 'weatherdaemon':
      return <WeatherGaugePage contentId={id ?? 0} slideIndex={slideIndex} isActive={ isActive } />
    case 'zeroaccident':
      return <ZeroAccidentPage contentId={id ?? 0} slideIndex={slideIndex} isActive={ isActive } />
    case 'picture':
      return <PicturePage contentId={id ?? 0} slideIndex={slideIndex} isActive={ isActive } />
    case 'movie':
      return <MoviePage contentId={id ?? 0} slideIndex={slideIndex} isActive={ isActive } />
    case 'aicontent':
      return <AiContentPage contentId={id ?? 0} slideIndex={slideIndex} isActive={ isActive } />
    case 'html':
    case 'camera':
      return (
        <div>
          <div>まだ未実装です</div>
          <div>type: {type}</div>
        </div>
      )
    case undefined:
      return <div>typeが未定義です</div>
    default: {
      const strangeValue: never = type
      throw new Error(strangeValue)
    }
  }
}

export const Slideshow = () => {
  const {
    getSlideshowResponse,
    handleSlideChange,
    handleTouchStart,
    handleTouchEnd,
    handleAutoplay
  } = useSlideshow()

  useDownloadVideoFromGetSlideshowResponse(getSlideshowResponse)
  if (getSlideshowResponse?.slides == null) return <Loading />

  const slides = getSlideshowResponse.slides.filter((slide) => slide.type)

  // console.log(getSlideshowResponse)
  
  return (
    <div style={{ height: '100vh' }}>
      <Swiper
        autoplay
        loop={false} // REF: https://zenn.dev/attt/articles/swiper-loop-without-duplicate
        onSlideChange={handleSlideChange}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onAutoplay={handleAutoplay}
        style={{ height: '100%' }}
        rewind={ true }
      >
        {slides.map((slide, index) => (
          <SwiperSlide
            key={ index }
            data-swiper-autoplay={(slide.duration ?? 30) * 1000}
          >
            {
              ({ isActive }) => {
                return (
                  <Slide
                    responseSlide={slide}
                    slideIndex={index}
                    isActive={ (isActive) }
                  />
                )
              }
            }
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

import { useState, useRef, useEffect } from 'react'
import useInterval from 'use-interval'
import { AiContent } from 'generated/backendSchemas'
import { format } from 'date-fns'
import { Typewriter } from 'react-simple-typewriter'

export const AiContentPageDefault = ({ name, answer, display_date }: AiContent) => {
  const [key, setKey] = useState(0)
  const [pos, setPosition] = useState(0)
  const [[containerHeight, height], setHeight] = useState([0, 0])

  const containerRef = useRef<HTMLDivElement>(null)
  const textRef = useRef<HTMLDivElement>(null)
  
  useEffect(() => {
    if (textRef.current && containerRef.current) {
      setHeight( [Math.round(containerRef.current.getBoundingClientRect().height), Math.round(textRef.current.getBoundingClientRect().height)] )
    }
  }, [display_date])

  useInterval(() => {
    let _height = 0
    let _containerHeight = 0
    if (textRef.current && containerRef.current) {
      _height = Math.round(textRef.current.getBoundingClientRect().height)
      _containerHeight = Math.round(containerRef.current.getBoundingClientRect().height)
    }

    if (_height > _containerHeight) {
      setPosition(_containerHeight - _height);
    } else {
      setPosition(0)
    }
  }, 100);

  const getTopPsition = () => {
    return pos + 'px'
  }
  
  if (typeof answer === 'undefined') {
    answer = ''
  }

  const handleOnLoopDone = () => {
    setKey( key + 1 )
  }

  return (
  <div className="container schedule">
    <div className="page-contents">
      <header className="header" style={{ display: 'block' }}>
        <h1 className="header__title" style={{ fontSize : '5vh'}}>AIコンテンツ</h1>
        <div className="header__datetime" style={{ width : 'fit-content', marginLeft : 'auto' }}>
          <div className="header__date">
              <span>{ format(new Date(), 'M') }</span>
            <span className="header__datetime-unit">月</span>
              <span>{ format(new Date(), 'd') }</span>
            <span className="header__datetime-unit">日</span>
          </div>
          <div className="header__time">
            <span className="header__datetime-unit header__time-unit">{ format(new Date(), 'a') }</span>
              <span>{ format(new Date(), 'HH:mm') }</span>
          </div>
        </div>
      </header>

      <main className="contents">
        <section className="schedule-content">
            <h2 className="schedule-content__title">{name}</h2>
            <div style={{ height: '65vh', color: 'white', fontSize: '3vh', whiteSpace: "pre-wrap", overflow : 'hidden' }} ref={ containerRef }>
              <div style={{ position: 'relative', top: getTopPsition() }} ref={ textRef }>
                <Typewriter
                  key={key}
                  words={[answer]}
                  typeSpeed={100}
                  deleteSpeed={100}
                  loop={1}
                  cursor
                  cursorStyle='_'
                  onLoopDone={handleOnLoopDone}
                />
              </div>
            </div>
        </section>
      </main>

      </div>


      <footer className="footer">
        <div className="footer__note">
          <div className="marquee">
            <span></span>
          </div>
        </div>
      </footer>

    </div>
  )
}

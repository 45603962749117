import { SchedulePageDefault } from './Default'
import './index.scss'
import { Schedule } from 'generated/backendSchemas'

export type Props = {
  type: 'default' | 'eva' | 'patternC' | 'patternD'
  data: Schedule
}

export const Presenter = ({ type, data }: Props) => {
  switch (type) {
    case 'default':
      return <SchedulePageDefault {...data} />
    default: {
      throw new Error( type )
    }
  }
}

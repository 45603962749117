import { ZeroAccident } from 'generated/backendSchemas'
import { format } from 'date-fns'

import './style.css'
import './vibration.css'
import './schedule.css'
import './font.css'

export const ExamplePageDefault = ({ display_date, contract_name, start_date, target_days, diff }: ZeroAccident) => {
  return (
    <div className="container schedule">
        <main className="contents">
          <section className="schedule-content" style={{ height : '100vh', width : '100vw' }}>
            <div style={{ height: '74vh', display: 'flex', flexFlow: 'column', alignItems: 'center', justifyContent: 'space-around' }}>
              <div style={{ color: 'white', fontSize: '5vh', fontWeight : 'bold', width: '100%', textAlign : 'left', marginTop : '2vh' }}>無災害記録表</div>
              <div style={{ color: 'white', fontSize: '6vh', fontWeight : 'bold', width: '100%', textAlign : 'left', marginTop : '2vh' }}>{ contract_name }</div>
              <div style={{ fontFamily : 'DigitalNumbers', width : '90vw', textAlign : 'right', fontSize : '16vh', display : 'flex', flexFlow: 'column', alignItems: 'right', justifyContent: 'space-around', gap : '1vw'}}>
                <div style={{ color: 'white', width: '80vw', display : 'flex', justifyContent : 'end' }}>
                  <div>
                    {display_date && format(new Date(display_date), 'MM')}<span style={{ fontSize : '8vh' }}>月</span>{display_date && format(new Date(display_date), 'dd')}<span style={{ fontSize : '8vh' }}>日</span>
                  </div>
                  <div style={{ width: '10vw' }}>
                    <span style={{ fontSize : '4vh' }}>現在</span>
                  </div>
                </div>
                <div style={{ color: 'white', width: '80vw', display : 'flex', justifyContent : 'end' }}>
                  <div>
                    { diff }
                  </div>
                  <div style={{ width: '10vw' }}>
                    <span style={{ fontSize : '4vh' }}>日</span>
                  </div>
                </div>
                <div style={{ color: 'white', width: '80vw', display : 'flex', justifyContent : 'end' }}>
                  <div>
                    { target_days }
                  </div>
                  <div style={{ width: '10vw' }}>
                    <span style={{ fontSize : '4vh' }}>日</span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
    </div>
  )
}

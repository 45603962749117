import { ExamplePageDefault } from './Default'
import './index.scss'
import { ZeroAccident } from 'generated/backendSchemas'

export type Props = {
  type: 'default' | 'eva' | 'patternC' | 'patternD'
  data: ZeroAccident
}

export const Presenter = ({ type, data }: Props) => {

  return <ExamplePageDefault {...data} />
}

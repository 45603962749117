import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import ReconnectingWebSocket  from 'reconnecting-websocket'

import './styles/_reset.scss'


const webSocket = new ReconnectingWebSocket('wss://dev-api.envis-dx.jp/wss/upgrade')
webSocket.addEventListener('open', (ev) => {
  console.log('WS OPEN!')
})

webSocket.onmessage = (ev) => {
  console.log('WS MESSAGE')
  if (typeof window !== 'undefined') {
    const code = localStorage.getItem('code')
    if (code) {
      location.href = `/auth/${code}`
    }
  }
}

/* --
webSocket.onopen = (ev) => {
  console.log('WS OPEN!')
}


webSocket.onclose = (ev) => {
  console.log('WS CLOSE')
  webSocket.reconnect()
}
-- */

// webSocket.onmessage = onMessage;
// webSocket.onclose = onClose;
// webSocket.onerror = onError;

// NOTE: 何かしら壊れた時のために定期的にリロードする
/* ---
2024.09.05 別途検討
setTimeout(() => {
  const code = localStorage.getItem('code')
  if (code) {
    location.href = `/auth/${code}`
  }
}, 1000 * 60 * 60)
--- */

/* --
setTimeout(() => {
  if (typeof window !== 'undefined') {
    window.location.reload()
  }
}, 1000 * 60)
-- */

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

/* --
ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <App />
)
-- */

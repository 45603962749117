import { useGetZeroAccident } from 'generated/backendComponents'
import { Loading } from 'src/components/atoms/Loading'
import { usePrefetch, useWindowResize } from 'src/hooks'
import { Presenter as HorizontalPresenter } from './Horizontal/Presenter'
import { Presenter as TabletHorizontalPresenter } from './TabletHorizontal/Presenter'
import { Presenter as VerticalPresenter } from './Vertical/Presenter'
import { Presenter as TabletVerticalPresenter } from './TabletVertical/Presenter'
import { Presenter as SquarePresenter } from './Square/Presenter'
import { useSizeStore } from 'src/zustand/useSizeStore'

type Props = {
  slideIndex: number
  contentId: number
  isActive: boolean
}

export const ZeroAccidentPage = ({ contentId, slideIndex, isActive }: Props) => {
  const { data, refetch } = useGetZeroAccident(
    {
      pathParams: { contentId: String(contentId) }
    },
    { enabled: false }
  )
  usePrefetch({ prefetch: refetch, slideIndex })
  const { size } = useSizeStore()

  const { isLandScape } = useWindowResize()

  const type = 'default' as any

  if (!data) return <Loading />

  return (
    <>
      { (size === 'square') && 
        <SquarePresenter {...{ type, data }} />
      }
      { (size === 'default') && 
        <HorizontalPresenter {...{ type, data }} />
      }
      { (size === 'tablethorizontal') && 
        <TabletHorizontalPresenter {...{ type, data }} />
      }
      { (size === 'vertical') && 
        <VerticalPresenter {...{ type, data }} />
      }
      { (size === 'tabletvertical') && 
        <TabletVerticalPresenter {...{ type, data }} />
      }
    </>
  )
}

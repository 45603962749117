import { ExamplePageDefault } from './Default'
import './index.scss'
import { ZeroAccident } from 'generated/backendSchemas'

export type Props = {
  type: 'default' | 'eva' | 'patternC' | 'patternD'
  data: ZeroAccident
}

export const Presenter = ({ type, data }: Props) => {
  return <ExamplePageDefault {...data} />

  /*
  switch (type) {
    case 'default':
      return <ExamplePageDefault {...data} />
    case 'eva':
      return <ExamplePageEva {...data} />
    case 'patternC':
      return <PatternC {...data} />
    case 'patternD':
      return <PatternD {...data} />
    default: {
      const unknown: never = type
      throw new Error(unknown)
    }
  }
*/
}

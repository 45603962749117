import { useState, useEffect } from 'react'
import { AiContentPageDefault } from './Default'
import './index.scss'
import { AiContent } from 'generated/backendSchemas'

export type Props = {
  type: 'default' | 'eva' | 'patternC' | 'patternD'
  data: AiContent
  isActive: boolean
}

export const Presenter = ({ type, data, isActive }: Props) => {
  const [active, setActive] = useState( false )

  useEffect(() =>{
    setActive(isActive)
  }, [isActive])

  switch (type) {
    case 'default':
      if (isActive) {
        return <AiContentPageDefault {...data} />
      } else {
        return <>in-active</>
      }
    default: {
      throw new Error( type )
    }
  }
}

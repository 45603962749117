import { useGetNoiseVibration } from 'generated/backendComponents'
import { usePrefetch, useWindowResize } from 'src/hooks'
import { Presenter as HorizontalPresenter } from './Horizontal/Presenter'
import { Presenter as VerticalPresenter } from './Vertical/Presenter'
import { Presenter as TabletHorizontalPresenter } from './TabletHorizontal/Presenter'
import { Presenter as TabletVerticalPresenter } from './TabletVertical/Presenter'
import { Presenter as SquarePresenter } from './Square/Presenter'
import { useSubscribe } from './hooks'
import { useEffect, useState } from 'react'
import { ExtendedNoiseVibration } from './types'
import { Loading } from 'src/components/atoms/Loading'
import { useSizeStore } from 'src/zustand/useSizeStore'

// AXIOS
import axios from "axios"
import { useQuery } from '@tanstack/react-query'
import mqtt from 'mqtt'

// MQTT
const clientId = 'mqttjs_' + Math.random().toString(16).substr(2, 8);
const mqttUri = 'wss://a2vcuhgxqo3y5y-ats.iot.ap-northeast-1.amazonaws.com/mqtt?x-amz-customauthorizer-name=envis-develop-authorizer';
const options = {
  keepalive: 60,
  clientId: clientId,
  clean: true, 
  reconnectPeriod: 1000, 
  connectTimeout: 30 * 1000,
}

type Props = {
  slideIndex: number
  contentId: number
  isActive: boolean
}

export const NoisePage = ({ contentId, slideIndex, isActive }: Props) => {
  const [mqttConnected, setMqttConnected] = useState(false)
  const [vndata, setVndata] = useState<ExtendedNoiseVibration>({
    noiseValue: '0',
    vibrationValue: '0',
    contractName: '',
    areaName: ''
  })
  const { size } = useSizeStore()

  const type = 'default' as any

  // GET SERIAL NUMBER
  const { isLoading, error, data } = useQuery([clientId], () => axios.get('https://dev-api.envis-dx.jp/api/contents/vnmeter/' + contentId))
  // console.log(data?.data)
  const serialNumber = data ?.data.serialNumber
  const accessKey = data?.data.accessKey
  const contractName = data?.data.contractName

  // MQTT
  if (!isLoading && !error && !mqttConnected) {
    const url = mqttUri + '&sn=' + serialNumber + '&key=' + accessKey

    const client = mqtt.connect(url, options)

    client.on('error', (err) => {
      console.log('Connection error: ', err)
    })

    client.on('connect', () => {
      client.subscribe('envis-develop/data/1/' + serialNumber);
    })

    client.on('message', (topic, message: Buffer) => {
      // console.log(topic, message.toString())
      let json = JSON.parse(message.toString())
      if(json.v != undefined && json.v != null && json.n != undefined && json.v != undefined) {
        setVndata({
          noiseValue: Math.round( parseFloat(json.n) ).toString(),
          vibrationValue: Math.round( parseFloat(json.v) ).toString(),
          contractName: contractName,
          areaName: json.wbgt_text
        })
      }
    })

    setMqttConnected(true)
  }

  if(isLoading){
    return <Loading />
  }

  if(error){
    return (
      <>
        <div>Error!</div>
      </>
    )
  }
  
  return (
    <>
      { (size === 'square') && 
        <SquarePresenter {...{ type, vndata }} />
      }
      { (size === 'default') && 
        <HorizontalPresenter {...{ type, vndata }} />
      }
      { (size === 'tablethorizontal') && 
        <TabletHorizontalPresenter {...{ type, vndata }} />
      }
      { (size === 'vertical') && 
        <VerticalPresenter {...{ type, vndata }} />
      }
      { (size === 'tabletvertical') && 
        <TabletVerticalPresenter {...{ type, vndata }} />
      }
    </>
  )
}

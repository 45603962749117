import { ExtendedNoiseVibration } from '../../types'
import { getNoiseImage, getVibrationImage } from './utils'
import { format } from 'date-fns'

// import './normalize.css'
import './fonts.css'
import './style.css'
import './vibration.css'

export const ExamplePageDefault = ({
  noiseValue,
  vibrationValue,
  contractName
}: ExtendedNoiseVibration) => {  

  const noises = getNoiseImage(Number(noiseValue))
  const vibrations = getVibrationImage(Number(vibrationValue))

  return (
  <div className="container vibration">
    <div className="page-contents">
      <header className="header" style={{ display: 'block', height : '12vh' }}>
        <h1 className="header__title" style={{ fontSize : '5vw'}}>現在の騒音・振動</h1>
          <div className="header__datetime" style={{ width : 'fit-content', marginLeft : 'auto' }}>
            <div className="header__date">
                <span>{ format(new Date(), 'M') }</span>
              <span className="header__datetime-unit">月</span>
                <span>{ format(new Date(), 'd') }</span>
              <span className="header__datetime-unit">日</span>
            </div>
            <div className="header__time">
              <span className="header__datetime-unit header__time-unit">{ format(new Date(), 'a') }</span>
                <span>{ format(new Date(), 'HH:mm') }</span>
            </div>
          </div>
      </header>

      <main className="contents">
        <section className="vibration-content" style={{ width : '86vw' }}>
            <h2 className="vibration-content__title">{ contractName }</h2>
          <div className="vibration-content__list" style={{ display : 'block'}}>
            <section className="vibration-content__item" style={{ width : '74vw', height : '30vh', marginBottom : '5vh' }}>
              <header className="vibration-content__header" style={{ display : 'flex', height : '28vh' }}>
                <h3 className="vibration-content__item-heading" style={{ width : '6vw', fontSize : '5vh' }}>騒<br />音</h3>
                <div className="vibration-content__item-value font-numbers" style={{ width : '25vh', fontSize : 'calc((200 / 1080) * 100vh)' }}><span>{ noiseValue }</span></div>
                <div className="vibration-content__item-unit" style={{ width : '20vw', display : 'block' }}>dB</div>
              </header>
            </section>
            <section className="vibration-content__item" style={{ width : '74vw', height : '30vh' }}>
              <header className="vibration-content__header" style={{ display : 'flex', height : '28vh' }}>
                <h3 className="vibration-content__item-heading" style={{ width : '6vw', fontSize : '5vh' }}>振<br />動</h3>
                <div className="vibration-content__item-value font-numbers" style={{ width : '25vh', fontSize : 'calc((200 / 1080) * 100vh)' }}><span>{ vibrationValue }</span></div>
                <div className="vibration-content__item-unit" style={{ width : '20vw', display : 'block' }}>dB</div>
              </header>
            </section>
          </div>
        </section>
      </main>

      </div>
    <footer className="footer">
      <div className="footer__note">
        <div className="marquee">
          <span></span>
        </div>
      </div>
    </footer>
  </div>
  )
}

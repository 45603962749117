import { ExamplePageDefault } from './Default'
import './index.scss'
import { Icc } from 'generated/backendSchemas'

export type Props = {
  type: 'default' | 'eva'
  weatherdata: Icc | null
}

export const Presenter = ({ type, weatherdata }: Props) => {
console.log(weatherdata)

  if (weatherdata !== null) {
    switch (type) {
      case 'default':
        return <ExamplePageDefault {...weatherdata} />
      default: {
        throw new Error( type )
      }
    }
  } else {
    return <></>
  }
}

import { Util } from 'src/utils'
import { formatDate } from 'src/utils/formatDate'
import { ZeroAccident } from 'generated/backendSchemas'
import { format } from 'date-fns'
import rainyPercentIconPng from 'src/assets/images/weather-forecast/blackBoard/rainy_percent_icon.png'

import './style.css'
import './vibration.css'
import './schedule.css'
import './font.css'

export const ExamplePageDefault = ({ display_date, contract_name, start_date, target_days, diff }: ZeroAccident) => {
  return (
    <div className="container schedule">
      <div className="page-contents" style={{ height : '95vh' }}>
        <header className="header">
          <h1 className="header__title" style={{ fontSize : '5vh' }}>無災害記録表</h1>
{/*
          <div className="header__datetime">
            <div className="header__date">
                <span>{ display_date && format(new Date(display_date), 'M') }</span>
              <span className="header__datetime-unit">月</span>
                <span>{ display_date && format(new Date(display_date), 'd') }</span>
              <span className="header__datetime-unit">日</span>
            </div>
            <div className="header__time">
              <span className="header__datetime-unit header__time-unit">{ display_date && format(new Date(display_date), 'a') }</span>
                <span>{ display_date && format(new Date(display_date), 'HH:mm') }</span>
            </div>
          </div>
*/}
        </header>

        <main className="contents">
          <section className="schedule-content" style={{ height : '78vh' }}>
            <div style={{ height: '74vh', display: 'flex', flexFlow: 'column', alignItems: 'center', justifyContent: 'space-around' }}>
              <div style={{ color: 'white', fontSize: '5vh', fontWeight : 'bold', width: '100%', textAlign : 'left', marginTop : '2vh' }}>{ contract_name }</div>
              <div style={{ fontFamily : 'DigitalNumbers', width : '90vw', textAlign : 'right', fontSize : '16vw', display : 'flex', flexFlow: 'column', alignItems: 'right', justifyContent: 'space-around', gap : '1vw'}}>
                <div style={{ display : 'flex' }}>
                  <div style={{ fontSize : '6vw', color: '#DDD', width: '40vw', display : 'flex', justifyContent : 'end' }}>
                    <div>
                      {display_date && format(new Date(display_date), 'MM')}<span style={{ fontSize : '3vw' }}>月</span>{display_date && format(new Date(display_date), 'dd')}<span style={{ fontSize : '3vw' }}>日 現在</span>
                    </div>
                  </div>
                  <div style={{ color: 'green', width: '40vw', display : 'flex', justifyContent : 'end' }}>
                    <div style={{ backgroundColor : '#EEE' }}>
                      { diff }
                    </div>
                    <div style={{ width: '10vw' }}>
                      <span style={{ fontSize : '8vw', color : 'white' }}>日</span>
                    </div>
                  </div>
                </div>
                <div style={{ color: 'white', width: '80vw', display : 'flex', justifyContent : 'end' }}>
                  <div style={{ width: '40vw' }}>
                    <span style={{ fontSize : '6vw', color : '#DDD' }}>目標</span>
                  </div>
                  <div>
                    { target_days }
                  </div>
                  <div style={{ width: '10vw' }}>
                    <span style={{ fontSize : '8vw' }}>日</span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
      <footer className="footer" style={{ height : '5vh' }}>
        <div className="footer__note">
          <div className="marquee">
            <span></span>
          </div>
        </div>
      </footer>
    </div>
  )
}

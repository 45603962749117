import { useGetIcc } from 'generated/backendComponents'
import { Loading } from 'src/components/atoms/Loading'
import { usePrefetch, useWindowResize } from 'src/hooks'
import { Presenter as HorizontalPresenter } from './Horizontal/Presenter'
import { Presenter as TabletHorizontalPresenter } from './TabletHorizontal/Presenter'
import { Presenter as VerticalPresenter } from './Vertical/Presenter'
import { Presenter as TabletVerticalPresenter } from './TabletVertical/Presenter'
import { Presenter as SquarePresenter } from './Square/Presenter'

import { useSizeStore } from 'src/zustand/useSizeStore'

// AXIOS
import { useState } from 'react'

import axios from "axios"
import { useQuery } from '@tanstack/react-query'
import mqtt from 'mqtt'
import { Icc } from 'generated/backendSchemas'

// MQTT
const clientId = 'mqttjs_' + Math.random().toString(16).substr(2, 8);
const mqttUri = 'wss://a2vcuhgxqo3y5y-ats.iot.ap-northeast-1.amazonaws.com/mqtt?x-amz-customauthorizer-name=envis-develop-authorizer';
const options = {
  keepalive: 60,
  clientId: clientId,
  clean: true, 
  reconnectPeriod: 30000, 
  connectTimeout: 30 * 1000,
}

type Props = {
  slideIndex: number
  contentId: number
  isActive: boolean
}

export const WeatherGaugePage = ({ contentId, slideIndex, isActive }: Props) => {
  const [mqttConnected, setMqttConnected] = useState(false)
  const [weatherdata, setWeatherdata] = useState<Icc | null>(null)
  const { size } = useSizeStore()

  const { isLoading, error, data } = useQuery([clientId], () => axios.get('https://dev-api.envis-dx.jp/api/contents/weatherdaemon/' + contentId))

  const { isLandScape } = useWindowResize()

  // TODO: 設定値を取得
  const type = 'default' as any

  if (isLoading) return <Loading />
  if(error){
    return (
      <>
        <div>Error!</div>
      </>
    )
  }

  if (weatherdata === null) {
    setWeatherdata({
      "display_date": data?.data.display_date,
      "temperature": data?.data.temperature,
      "highest": 0,
      "lowest": 0,
      "humidity": data?.data.humidity,
      "max_wind_speed": data?.data.max_wind_speed,
      "average_wind_speed": data?.data.average_wind_speed,
      "wind_direction": data?.data.wind_direction,
      "wbgt_value": 0,
      "rainfall": data?.data.rainfall,
      "rainfall_24": data?.data.rainfall_24,
      "rainfall_day": data?.data.rainfall_day,
      "barometric_pressure": data?.data.barometric_pressure,
      "contract_name": data?.data.contract_name,
      "accessKey": "",
      "serialNumber": ""
    })
  }

  const serialNumber = data?.data.serialNumber
  const accessKey = data?.data.accessKey

  // MQTT
  if (mqttConnected === false) {
    const url = mqttUri + '&sn=' + serialNumber + '&key=' + accessKey

    const client = mqtt.connect(url, options)

    client.on('error', (err) => {
      console.log('Connection error: ', err)
    })

    client.on('connect', () => {
      client.subscribe('envis-develop/data/0/' + serialNumber);
    })

    client.on('message', (topic, message: Buffer) => {
      let json = JSON.parse(message.toString())

      if (json.epochTime !== undefined && json.epochTime !== null) {
        let dateTime = new Date(json.epochTime * 1000)

        setWeatherdata({
          "display_date": dateTime.toLocaleString().replaceAll('/', '-'),
          "temperature": json.temperature,
          "highest": 0,
          "lowest": 0,
          "humidity": json.humidity,
          "max_wind_speed": json.gust_speed,
          "average_wind_speed": json.wind_speed,
          "wind_direction": json.wind_direction,
          "wbgt_value": 0,
          "rainfall": json.rainfall_60min,
          "rainfall_24": json.rainfall_24hour,
          "rainfall_day": json.rainfall_1day,
          "barometric_pressure": json.pressure,
          "contract_name": data?.data.contract_name,
          "accessKey": "",
          "serialNumber": ""
        })
      }
    })

    setMqttConnected(true)
  }

  return (
    <>
      { (size === 'square') && 
        <SquarePresenter {...{ type, weatherdata }} />
      }
      { (size === 'default') && 
        <HorizontalPresenter {...{ type, weatherdata }} />
      }
      { (size === 'tablethorizontal') && 
        <TabletHorizontalPresenter {...{ type, weatherdata }} />
      }
      { (size === 'vertical') && 
        <VerticalPresenter {...{ type, weatherdata }} />
      }
      { (size === 'tabletvertical') && 
        <TabletVerticalPresenter {...{ type, weatherdata }} />
      }
    </>
  )

}
